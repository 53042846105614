import React,{useEffect, useState} from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import BookingSchedule from "../components/teacher/booking-schedule";
import { getMethod, postMethod, putMethod,deleteMethod} from "../helper/api"
import { notify } from 'react-notify-toast';
import { connect } from 'react-redux';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import sleep from 'sleep-promise';

const TeacherSchedule =({user})=>{
    const [data, setAppointments] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [load, setLoad] = useState(1);
    const [showL, setShowL] = useState(false);

    async function setSlots(slots){
        let schedules = [];
            for(let i=0; i<slots.data.length;i++){
        
                let obj = {};
                obj.id = slots.data[i].id;

                //console.log("DATE:::",JSON.parse(slots.data[i].data));
                if(slots.data[i].data===undefined) continue;
                obj.startDate = moment(JSON.parse(slots.data[i].data).startDate).toDate();
                obj.data = slots.data[i].data;

                
                obj.endDate = moment(JSON.parse(slots.data[i].data).endDate).toDate();
                obj.type = JSON.parse(slots.data[i].data).type ? JSON.parse(slots.data[i].data).type : '1' ;
                schedules.push(obj);
                //setHolidays(resp2.data);
            }

            //async function getAppointments(){
            
            let resp = await getMethod('/bookings',"",{faculty:user.id, payment:1, date_gte:new Date().toISOString().slice(0,10),'isCancelled_ne':true});
            for(let i=0; i<resp.data.length;i++){
                let obj = {};
                obj.id = resp.data[i].id;
                obj.startDate = moment.utc(resp.data[i].start_time).local().toDate();
                obj.endDate = moment.utc(resp.data[i].end_time).local().toDate();
                obj.type =  '4' ;
                //obj.onDoubleClick =  () => { alert("called") };
                obj.title = `${resp.data[i].subject?.name} - ${resp.data[i].booked_by?.firstname}`
                obj.bookingdata = resp.data[i];
                schedules.push(obj);
                //setHolidays(resp2.data);
                //setAppointments(schedules)
            }

            let resp2 = await getMethod('/holidays',"",{date_gte:new Date().toISOString().slice(0,10)});
            for(let i=0; i<resp2.data.length;i++){
                let obj = {};
                obj.id = resp2.data[i].id;
                obj.startDate = moment(`${resp2.data[i].date}T00:00:00`).toDate();
                obj.endDate = moment(`${resp2.data[i].date}T23:59:59`).toDate();
                obj.type =  '3' ;
                //obj.onDoubleClick =  () => { alert("called") };
                obj.title = `Holiday - ${resp2.data[i].title}`
                schedules.push(obj);
                
                //setAppointments(schedules);
                
                
            }

            setHolidays(resp2.data);
            setAppointments(schedules);
            setLoad(load+1);
            setShowL(false);
    }

    async function reloadSlots(){
        
        setShowL(true);
        let resp = await getMethod('/schedules',"",{user_id:user.id, date_gte:new Date().toISOString().slice(0,10), _sort: "date:ASC", _limit: 100000});
        setSlots(resp)
    }

    useEffect(()=>{
       
        async function getAppointments(){
            let resp = await getMethod('/schedules',"",{user_id:user.id, date_gte:new Date().toISOString().slice(0,10),_sort: "date:ASC", _limit: 100000});
            setSlots(resp)
        }
       
        if(user!==undefined) getAppointments();

    },[user])

    

    async function removeSlots(id,data){
        
        let checkbookings = {data:[]};
        //console.log(data);
        if(data[0].type===1){
            checkbookings = await getMethod('/bookings',"",{ faculty:user.id, isCancelled_ne:true, start_time_gte:moment(data[0].startDate).format('YYYY-MM-DDTHH:mm:00Z'), start_time_lt:moment(data[0].endDate).format('YYYY-MM-DDTHH:mm:00')});
        }
        
        
        if(checkbookings.data?.length>0){


            let cancelMsg = "Are you sure to cancel this schedule?";
            let cancelTitle = "";
            let cancelValue = 0;
            for(let j=0; j< checkbookings.data.length; j++){
                if((new Date(checkbookings.data[j].start_time).getTime()-new Date().getTime())/(1000*60*60)<24){
                    //console.log(checkbookings.data[j])
                    if(checkbookings.data[j].isCancelled===true) continue;
                    cancelValue = cancelValue+((new Date(checkbookings.data[j].end_time).getTime()-new Date(checkbookings.data[j].start_time).getTime())/(1000*60*60))
                }
            }

            if(cancelValue>0){
                cancelTitle = 'Slots are booked against the schedule.'
                cancelMsg = `Are you sure you want to delete and cancel the lesson? Cancellation fee for ${cancelValue/2} hrs will be applicable`
            }

            confirmAlert({title: cancelTitle,
            message: cancelMsg,
            buttons: [
              {
                label: 'Yes',
                className: 'bg-red',
                onClick: async () => {
                    for(let j=0; j< checkbookings.data.length; j++){

                        //if(checkbookings.data[j].isCancelled) continue;
                        //console.log(checkbookings.data[j].isCancelled);
                        let resp = await postMethod('/cancel-bookings', { booking: checkbookings.data[j].id, reasons: "", remarks: "", Cancelled_by: user.id, donate: false });
                        let resp2 = await postMethod('/cancelbooking',{id:checkbookings.data[j].id,donate: false });
                      }

                      let deleted = await deleteMethod(`/schedules/${id}`,"",{});

                      let deleteSlots = await postMethod('/tutor/deleteslots',{type:data[0].type, faculty:user.id,start_time:new Date(moment(data[0].startDate).format("YYYY-MM-DD HH:mm:00")), end_time:new Date(moment(data[0].endDate).format("YYYY-MM-DD HH:mm:00"))});

                      let resp = await getMethod('/schedules',"",{user_id:user.id, date_gte:new Date().toISOString().slice(0,10),_sort: "date:ASC", _limit: 100000});
        
                      setSlots(resp)
                      
                }
              },
              {
                label: 'No',
                className: 'bg-green',
                onClick: async () => {
                    let resp = await getMethod('/schedules',"",{user_id:user.id, date_gte:new Date().toISOString().slice(0,10),_sort: "date:ASC", _limit: 100000});
        
                    setSlots(resp)
                }
              }
            ]})
        }else{

                let deleted = await deleteMethod(`/schedules/${id}`,"",{});

                let deleteSlots = await postMethod('/tutor/deleteslots',{type:data[0].type, faculty:user.id,start_time:new Date(moment(data[0].startDate).format("YYYY-MM-DDTHH:mm:00")), end_time:new Date(moment(data[0].endDate).format("YYYY-MM-DD HH:mm:00"))});

                let resp = await getMethod('/schedules',"",{user_id:user.id, date_gte:new Date().toISOString().slice(0,10),_sort: "date:ASC", _limit: 100000});
        
                setSlots(resp)
            }

            // setTimeout(
            //     () => notify.show("Bookings for the slot will be cancelled", "error"),
            //     100
            //   )
    }

    async function updateSlots(id,body){

        let deleted = await putMethod(`/schedules/${id}`,{body});
        let resp = await getMethod('/schedules',"",{user_id:user.id, date_gte:new Date().toISOString().slice(0,10),_sort: "date:ASC", _limit: 100000});
        setSlots(resp)
    }

    //console.log(data);

    return (
        <Layout title="Schedule your work hours">
        <Seo title="Schedule your work hours" />
        <div className="container animated fadeInLeft" >
        <div class="row mx-sm d-block">
            {showL && <div style={{position:'absolute',margin:'0px auto', top:'150px', left:'45%', color:'white', fontWeight:'bold', width:'90px', background:"red",zIndex:10000}}>Loading...</div>}
           <BookingSchedule data={data} setAppointments={setAppointments}  load={load} reloadSlots={reloadSlots} setSlots={setSlots} removeSlots={removeSlots} updateSlots={updateSlots} holidays={holidays} user={user}/>
            
        </div>
        <div class="row d-block mx-sm">
	    </div>
        </div>
        </Layout>
    )

}

export default connect(state => ({ user: state.loginReducer.authenticate.user }), null)(TeacherSchedule);
