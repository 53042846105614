import React, { useState,  useEffect, useCallback, useContext } from "react"
import { useDispatch, useSelector, connect, useReducer } from "react-redux"
import Paper from "@material-ui/core/Paper"
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler"
import {
  Scheduler,
  DayView,
  WeekView,
  Appointments,
  AppointmentTooltip,
  ConfirmationDialog,
  MonthView,
  AppointmentForm,
  Toolbar,
  DateNavigator,
  TodayButton,
  ViewSwitcher,
  EditRecurrenceMenu,
  Resources,
} from "@devexpress/dx-react-scheduler-material-ui"
import { fade } from "@material-ui/core/styles/colorManipulator"
import { makeStyles, alpha } from "@material-ui/core/styles"
import { getMethod, postMethod, putMethod } from "../../helper/api"
import { notify } from "react-notify-toast"
import { withStyles } from '@material-ui/core/styles';
import { navigate } from "gatsby";
import Rolldate from 'rolldate'


const DateContext = React.createContext({currentDate: Date.now(), setDate: () =>{}});

const useStyles = makeStyles(theme => ({
  todayCell: {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.14),
    },
    "&:focus": {
      backgroundColor: fade(theme.palette.primary.main, 0.16),
    },
  },
  weekendCell: {
    backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    "&:hover": {
      backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    },
    "&:focus": {
      backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    },
  },
  today: {
    backgroundColor: fade(theme.palette.primary.main, 0.16),
  },
  weekend: {
    backgroundColor: fade(theme.palette.action.disabledBackground, 0.06),
  },
}))

const TimeTableCell = props => {
  const classes = useStyles()
  const { startDate } = props
  const date = new Date(startDate)

  if (date.getDate() === new Date().getDate()) {
    return <WeekView.TimeTableCell {...props} className={classes.todayCell} />
  }
  if (date.getDay() === 0 || date.getDay() === 6) {
    return <WeekView.TimeTableCell {...props} className={classes.weekendCell} />
  }
  return <WeekView.TimeTableCell {...props} />
}

const DayScaleCell = props => {
  const classes = useStyles()
  const { startDate, today } = props




  if (today) {
    return <WeekView.DayScaleCell {...props} className={classes.today} />
  }
  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
    return <WeekView.DayScaleCell {...props} className={classes.weekend} />
  }
  return <WeekView.DayScaleCell {...props} />
}



const BasicLayout = ({ onFieldChange, appointmentData, user,  ...restProps }) => {

  const str = 'HH:mm';

  const onCustomFieldChange = nextValue => {

    
    onFieldChange({ type: nextValue })
    if(nextValue===5){
      onFieldChange({ isRepeat: false })
    }
    //setLoad(load+1);

    //console.log(nextValue);
    
  }

  async function checkHolidayBookings(startDate,endDate, repeat){

    //console.log(appointmentData);
    startDate = startDate?startDate:appointmentData.startDate;
    endDate = endDate?endDate:appointmentData.endDate;

    repeat = repeat!==undefined?repeat:appointmentData.isRepeat;
    
    let endDateFormat = 'HH:mm:00';

    let eDate = repeat?new Date(`${appointmentData.tillDate}`):endDate;

    let user = await getMethod('/users/me');

    let days = repeat?Object.keys(appointmentData.rdays):undefined;
    if(days!==undefined){
      days = days.map((item)=> appointmentData.rdays[item]?`"${item}"`:`""` )
    }

    const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');

    if(commandButtons){
      let pstartTime = moment(startDate).format('HH:mm:00');
      let pEndTime = moment(endDate).format(endDateFormat);
      if(appointmentData.type===5){
        pstartTime = "00:00:00";
        pEndTime = "23:59:59"; 
      }

      let checkbookings = await getMethod('/tutorbookings',"",{ type: appointmentData.type, faculty:user.data.id,days:days?.join(","), tz:moment(startDate).format('Z'), start_date:moment(startDate).format('YYYY-MM-DD'), end_date:moment(eDate).format('YYYY-MM-DD'), start_time:pstartTime, end_time:pEndTime});
      //console.log("StartEndDateError::",startDateError,'::',endDateError);
      //if(appointmentData.type===5) return;

      if(checkbookings.data?.data?.length>0 && appointmentData.type){

        commandButtons[1].style.display = 'none';
        setHolidayError(true);
      }else if(!startDateError && !endDateError){
        commandButtons[1].style.display = '';
        commandButtons[1].removeAttribute('disabled');
      commandButtons[1].removeAttribute('style');
        setHolidayError(false);
      }
    }
  
  }

  function onStartDateChange(nextValue) {
    //console.log(moment(moment(nextValue).format('yyyy-MM-DD')+ ' ' + moment(appointmentData.endDate).format('HH:mm')));
    //console.log("NNN::",nextValue,'=>',appointmentData.startDate);
    onFieldChange({tillDate:moment(nextValue).add(7,'days').format('yyyy-MM-DD')});
    onFieldChange({endDate: moment(moment(nextValue).format('yyyy-MM-DD')+ ' ' + moment(appointmentData.endDate).format('HH:mm'))});
    onFieldChange({ startDate: moment(nextValue) });
    
    //console.log(DateContext.Provider._context._currentValue);
    setDate(new Date(moment(nextValue).format('yyyy-MM-DD HH:mm:00')).getTime());
    //console.log(DateContext.Provider._context._currentValue);
    
  }

  function onEndDateChange(nextValue) {
    onFieldChange({ endDate: nextValue })
    setLoad(load+1);
  }

  function onStartTimeChange(nextValue) {
    //console.log(moment(nextValue).format('HH:mm:00'));
    let changeDate = moment(moment(appointmentData.startDate).format('yyyy-MM-DD ') + moment(nextValue).format('HH:mm:00'));
    //console.log(changeDate);
    onFieldChange({ startDate: changeDate })
    if(appointmentData.type!==1){
      checkHolidayBookings(changeDate,null);
    }
    setLoad(load+1);
  }

  function onEndTimeChange(nextValue) {
    let changeDate = moment(moment(appointmentData.startDate).format('yyyy-MM-DD ') + moment(nextValue).format('HH:mm:00'));
    
    if(moment(nextValue).format('HH:mm:00')==='00:00:00'){
      changeDate.add(24,"hours");
    }

    onFieldChange({ endDate: changeDate })
    if(appointmentData.type!==1){
      checkHolidayBookings(null,changeDate);
    }
    setLoad(load+1);
  }

  function onTillDateChange(nextValue) {
    //
      onFieldChange({ tillDate: nextValue.target.value })
      if(new Date(nextValue.target.value).getTime()<new Date().getTime()){
        setDateError(true);
      }else if (Math.floor((new Date(nextValue.target.value).getTime() - new Date(appointmentData.startDate).getTime())/(1000*60*60*24))>90){
        setDateError(true);
      }else{
        setDateError(false);
      }

      if(appointmentData.type!==1){
	const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].style.display = 'none';
        checkHolidayBookings(null,null);
      }
      setLoad(load+1);
  }

  function onisRepeatChange(nextValue) {
    onFieldChange({ isRepeat: nextValue })

    //console.log(nextValue);
    if(appointmentData.type!==1){
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].style.display = 'none';
      checkHolidayBookings(null,null,nextValue);
    }
    setLoad(load+1);
  }

  function onIsRepeatDaysChange(nextValue) {
    onFieldChange({ rdays: nextValue })
    if(appointmentData.type!==1){
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].style.display = 'none';
      checkHolidayBookings(null,null);
    }
    setLoad(load+1);
  }


  const {currentDate, setDate} = useContext(DateContext);
  let [sdays,setSDays] = useState({});
  let [load,setLoad] = useState(1);
  let [initType, setInitType] = useState(appointmentData.type);
  let [dateError,setDateError] = useState(false);
  let [startDateError,setStartDateError] = useState(false);
  let [endDateError,setEndDateError] = useState(false);
  let [holidayError,setHolidayError] = useState(false);
  let [slotError,setSlotError] = useState(false);
  let [initialStartDate, setInitialStartDate] = useState(moment(appointmentData.startDate));
  let [startRollDate, setStartRollDate] = useState(false);
  let [schedulerRef, setSchedulerRef] = useState(React.useRef(document.getElementsByClassName('main-sch')[0]));
  const [days,setDays]  = useState({ "Monday": true, "Tuesday": true, "Wednesday": true, "Thursday": true, "Friday": false, "Saturday": false, "Sunday": true });

  const changeRdays = (config) => {
    days[config['day']] = config['val'];
    onIsRepeatDaysChange(days);
  }

  //console.log(schedulerRef);

  appointmentData.rdays = days;

  function getStartDate(){
    return appointmentData.startDate;
  }

  async function checkHolidayBookings2(){

    if(appointmentData.type===1) return;

    const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
    commandButtons[1].setAttribute('disabled','disabled');
    commandButtons[1].setAttribute('style','background-color: #CCC !important');

    let user = await getMethod('/users/me');

    console.log(appointmentData.startDate);
    
    let checkbookings = await getMethod('/bookings',"",{ faculty:user.id, payment:1, isCancelled_ne:true,start_time_gte:moment.utc(moment(appointmentData.startDate).format('YYYY-MM-DDT00:00:00Z')).format('YYYY-MM-DD HH:mm:00'), start_time_lt:moment.utc(moment(appointmentData.startDate).format('YYYY-MM-DDT23:59:59Z')).format('YYYY-MM-DD HH:mm:00')});
    
    if(checkbookings.data?.length>0){

      commandButtons[1].style.display = 'none';
      setHolidayError(true);
    }else if(!startDateError && !endDateError){
      setHolidayError(false);
      //setLoad(load+1);
      commandButtons[1].removeAttribute('disabled');
      commandButtons[1].removeAttribute('style');
      commandButtons[1].style.display = '';
    }
  
  }

  useEffect(()=>{

    let nextValue = appointmentData.type;
    //console.log(appointmentData.type);
    if(appointmentData.type===5){
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].style.display = 'none';
      //checkHolidayBookings2();
    }
    else if(nextValue!==1){
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].style.display = 'none';
      
      //console.log(nextValue);
      //if(nextValue!=='5')
      checkHolidayBookings(null,null);
      
    }else{
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].style.display = '';
      //console.log(commandButtons[1])
      setHolidayError(false);
      //setLoad(load+1);
    }

    if(nextValue===3){
      //appointmentData.startDate = moment("2021-10-10 00:00:00");
      //appointmentData.endDate = moment("2021-10-10 23:59:00");

      //console.log(appointmentData);
      setLoad(load+1);
    }

    if(nextValue===5 || nextValue===2 || nextValue === 1){
      document.getElementById('shift_start_date').removeAttribute('disabled');
      //document.getElementById('shift_start_date').('readonly','readonly');
      document.getElementById('shift_start_date').setAttribute('readonly','readonly');
      document.getElementById('shift_start_date').style.color = "black";
      if(initType===undefined) {
     let rd = new Rolldate({
        el: '#shift_start_date',
        format: 'DD-MM-YYYY',
        beginYear: new Date().getFullYear(),
        value: moment(appointmentData.startDate).format('yyyy-MM-DD'),
        lang: { 
            title: 'Select A Date', 
            cancel: 'Cancel', 
            confirm: 'Confirm', 
            year: '', 
            month: '', 
            day: '', 
            hour: '', 
            min: '', 
            sec: '' 
          },
        confirm: function(date) {
            //console.log(date)
            //user.authenticate.user.date_of_birth = date;
            //setIsUpdated(true);
            //  console.log(moment(moment(date,`DD-MM-yyyy`).format('yyyy-MM-DD') + ' '+ moment(appointmentData.startDate).format('HH:mm')))
            //console.log("SSSA:::",initialStartDate, "=>", moment(appointmentData.startDate),'=>',moment(moment(date,`DD-MM-yyyy`).format('yyyy-MM-DD') + ' '+ moment(appointmentData.startDate).format('HH:mm')))
            console.log(document.querySelectorAll('input[placeholder="Custom field"]')[0].value);
            if(document.querySelectorAll('input[placeholder="Custom field"]')[0].value=='5'){
              onStartDateChange(moment(date,`DD-MM-yyyy`).format('yyyy-MM-DD'));
            }else{
              let tval = document.getElementsByClassName('rc-time-picker-input')[0].value;
              tval = tval.split(' ');
              let timeval = tval[0].split(':');

              
              if(parseInt(timeval[0])===12)
                timeval[0] = "0";

              
              if(tval[1].indexOf('am')!==-1){
                tval = timeval[0].padStart(2,"0") + ':' + timeval[1];
              }else{
                tval = parseInt(timeval[0])+ 12 + ':' + timeval[1];
              }
              
              let tval2 = document.getElementsByClassName('rc-time-picker-input')[1].value;
              tval2 = tval2.split(' ');
              timeval = tval2[0].split(':');

              if(parseInt(timeval[0])===12)
                timeval[0] = "0";

              if(tval2[1].indexOf('am')!==-1){
                tval2 = timeval[0].padStart(2,"0") + ':' + timeval[1];
              }else{
                tval2 = parseInt(timeval[0]) + 12 + ':' + timeval[1];
              }
              
              
              onStartDateChange(moment(moment(date,`DD-MM-yyyy`).format('yyyy-MM-DD') + ' '+ moment(moment(appointmentData.startDate).format('YYYY-MM-DD ')+tval).format('HH:mm')));
              onEndDateChange(moment(moment(date,`DD-MM-yyyy`).format('yyyy-MM-DD') + ' '+ moment(moment(appointmentData.startDate).format('YYYY-MM-DD ')+tval2).format('HH:mm')))
            }
            //console.log("SSS:::",initialStartDate, "=>", moment(appointmentData.startDate),'=>',moment(moment(date,`DD-MM-yyyy`).format('yyyy-MM-DD') + ' '+ moment(appointmentData.startDate).format('HH:mm')))
            //onEndDateChange(moment(date,'DD-MM-yyyy').format('yyyy-MM-DD'));
          },
        init: async function() {
          //return false;
          //let tval = document.getElementsByClassName('rc-time-picker-input')[0].value;
          //console.log("YYYY::",tval,"=>",moment(moment(appointmentData.startDate).format('YYYY-MM-DD ')+tval).format('yyyy-MM-DD HH:mm:00'));//.startOf(moment(appointmentData.startDate).format('HH:mm')));
          onStartDateChange(moment(appointmentData.startDate).startOf(moment(appointmentData.startDate).format('HH:mm')));
          
          setInitialStartDate(moment(appointmentData.startDate).startOf(moment(appointmentData.startDate).format('HH:mm')));
      	},
      })

      //setStartRollDate(true)
    }
    }else{
      if(startRollDate){
        // var old_element = document.getElementById("shift_start_date");
        // var new_element = old_element.cloneNode(true);
        // old_element.parentNode.replaceChild(new_element, old_element);
        // onStartDateChange(initialStartDate);
        // document.getElementById('shift_start_date').value = initialStartDate.format('DD-MM-YYYY');
        // document.getElementById('shift_start_date').setAttribute('disabled','disabled');
        // document.getElementById('shift_start_date').setAttribute('readonly','readonly');
        // document.getElementById('shift_start_date').removeAttribute('style');
        // setStartRollDate(false);
      }
    }

  },[appointmentData.type])

  useEffect(()=>{
    function enableDisableButtons(){
      
      //if(appointmentData.type==='4'){
        //console.log("run::",formVisible);
        
        const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
       
        for(let i=2;i<commandButtons?.length; i++){

          //console.log(commandButtons[i]);
          //commandButtons[i].style.display = (appointmentData.type==='4' || appointmentData.type==='3')?'none':'';
        
          //if(appointmentData.type===1 && i==2){
            commandButtons[i].style.display = 'none';
          //}
        
        }

        if(dateError || appointmentData.type===undefined || startDateError || endDateError || slotError){
          commandButtons[1].style.display = 'none';
        }else{
          commandButtons[1].style.display = '';
        }

        if(appointmentData.type==='4'){
          commandButtons[1].style.display = 'none';
        }
    }
    
    if(appointmentData.type!=='5')
	  enableDisableButtons();

    if(!appointmentData.tillDate){
      
      if(appointmentData.type===undefined){
        // onCustomFieldChange(1)
        // onisRepeatChange(true)
        // onIsRepeatDaysChange({ "Monday": true, "Tuesday": true, "Wednesday": true, "Thursday": true, "Friday": false, "Saturday": false, "Sunday": true });
        // onTillDateChange({"target":{"value":moment(appointmentData.startDate).add(7,'days').format('yyyy-MM-DD')}});

        onFieldChange({type:1});
        onFieldChange({isRepeat:true});
        onFieldChange({rdays:{ "Monday": true, "Tuesday": true, "Wednesday": true, "Thursday": true, "Friday": false, "Saturday": false, "Sunday": true }});
        onFieldChange({tillDate: moment(appointmentData.startDate).add(7,'days').format('yyyy-MM-DD')});
      }

    };

    

    if(appointmentData.type===5){
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].setAttribute('disabled','disabled');
      commandButtons[1].setAttribute('style','background-color: #CCC !important');
      checkHolidayBookings2();
      checkHolidayBookings();
    }
   

    //console.log(appointmentData.type);
    

    

    new Rolldate({
      el: '#untillDate',
      format: 'DD-MM-YYYY',
      beginYear: new Date().getFullYear(),
      value: moment(appointmentData.startDate).add(7,'days').format('yyyy-MM-DD'),
      lang: { 
          title: 'Select A Date', 
          cancel: 'Cancel', 
          confirm: 'Confirm', 
          year: '', 
          month: '', 
          day: '', 
          hour: '', 
          min: '', 
          sec: '' 
        },
      confirm: function(date) {
          //console.log(date)
          //user.authenticate.user.date_of_birth = date;
          //setIsUpdated(true);
          onTillDateChange({"target":{"value":moment(date,'DD-MM-yyyy').format('yyyy-MM-DD')}});
        },
      init: function(){
	      onTillDateChange({"target":{"value":moment(appointmentData.startDate).add(7,'days').format('yyyy-MM-DD')}});
        document.getElementById('untillDate').value = moment(appointmentData.startDate).add(7,'days').format('DD-MM-YYYY');
        setLoad(load+1);
      },
    })

    //console.log("Called");
    if(moment(appointmentData.startDate) < moment.now()){
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].style.display = 'none';
      setStartDateError(true)
      //console.log("Comes here");
      //setLoad(load+1);
    }else{
      setStartDateError(false)
      //setLoad(load+1);
    }

    //console.log(moment(appointmentData.endDate) ,"::", moment(appointmentData.startDate), '::',moment(appointmentData.endDate)< moment(appointmentData.startDate))

    if(moment(appointmentData.endDate)< moment.now() || moment(appointmentData.endDate) < moment(appointmentData.startDate)){
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].style.display = 'none';
      setEndDateError(true)
      //setLoad(load+1);
    }else{
      setEndDateError(false)
      //setLoad(load+1);
    }
  },[load, dateError,startDateError, endDateError, appointmentData.type, appointmentData.isRepeat, appointmentData.endDate])

  useEffect(()=>{
    //console.log(load);
    //document.getElementsByClassName('main-sch')[0].scrollIntoView();
    window.scrollTo(0,0);
    document.getElementsByClassName('MuiPaper-root')[0].firstChild.firstChild.scrollIntoView();
    
    
    //setTimeout(()=>{document.body.style.overflow = 'hidden'},500);
    if(moment(appointmentData.startDate)<moment(moment().add(-1,'days').format('YYYY-MM-DDT23:59:59Z'))){
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].style.display = 'none';
      notify.hide();
      notify.show("Schedules cannot be added for past date", "error",2000);
      commandButtons[0].click();
    }
    setTimeout(()=>{setLoad(load+1)},2000);
    return () => {
      document.body.style.overflow = ''
    }

    
    
  },[])

  useEffect(()=>{
    
    setLoad(load+1);
  },[appointmentData.startDate]);

  useEffect(()=>{
    //console.log(load);
    //document.getElementsByClassName('main-sch')[0].scrollIntoView();
    async function checkSlotAvailable(){

      let user = await getMethod('/users/me');
      
      let checkbookings = await getMethod('/slots',"",{ faculty:user.id, date: moment.utc(appointmentData.startDate).format('YYYY-MM-DD'), start_time_gte:moment.utc(appointmentData.startDate).format('HH:mm:00'), end_time_lte:moment.utc(appointmentData.endDate).format('HH:mm:00')});
      
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      if(checkbookings.data?.length>0){

        commandButtons[1].style.display = 'none';
        setSlotError(true);
      }else if(!startDateError && !endDateError){
        setSlotError(false);
        
        //commandButtons[1].style.display = '';
      }
    
    }

    if(appointmentData.type===1){
     // checkSlotAvailable();
    }

    if(moment(appointmentData.startDate) < moment.now()){
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].style.display = 'none';
      setStartDateError(true)
      //console.log("Comes here");
      //setLoad(load+1);
    }else{
      setStartDateError(false)
      //setLoad(load+1);
    }

    //console.log(moment(appointmentData.endDate) ,"::", moment(appointmentData.startDate), '::',moment(appointmentData.endDate)< moment(appointmentData.startDate))

    if(moment(appointmentData.endDate)< moment.now() || moment(appointmentData.endDate) < moment(appointmentData.startDate)){
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      commandButtons[1].style.display = 'none';
      setEndDateError(true)
      //setLoad(load+1);
    }else{
      setEndDateError(false)
      //setLoad(load+1);
    }


    
  },[appointmentData.type, appointmentData.startDate, appointmentData.endDate])




  

  //console.log(moment().add(5,"days").format('YYYY-MM-DD'));

  //onStartDateChange(moment(appointmentData.startDate,'DD/MM/yyyy').format('yyyy-MM-DD'));
  
  //console.log("AAAA:",appointmentData.startDate,"=>",appointmentData.endDate);

  useEffect(()=>{

    document.getElementsByClassName('main-sch')[0].style.height = window.innerHeight+'px';

    return () => {
      document.getElementsByClassName('main-sch')[0].style.height = '100%';
    }
  },[]);

  let openClose = initType!==undefined?{open:false}:{};
  //console.log(new Date(appointmentData.tillDate).getTime(),((new Date(appointmentData.tillDate).getTime()-new Date(appointmentData.startDate).getTime())/(1000*60*60*24)));
  return (
    <>
      <>{moment(appointmentData.startDate)<moment().add(-1,'days') && <div style={{fontSize:'24px', color:'#FF0000'}}>Schedule cannot be added for past date</div>}</>
      
       <>
      
      {load && moment(appointmentData.startDate)>moment().add(-1,'days') && <div id="appointmentFormContainer" className="container">

      
      <AppointmentForm.Label text={appointmentData.type==='4' || appointmentData.type==='3'? appointmentData.title: 'type'} type="title" startDateError = {startDateError} endDateError={endDateError} />
      {appointmentData.type!=='4' && <><AppointmentForm.Select
          value={appointmentData.type!==undefined?appointmentData.type:1}
          onValueChange={onCustomFieldChange}
          placeholder="Custom field"
          availableOptions={initType===undefined?[
            { id: 1, text: "Available " },
            { id: 2, text: "Break" },
            { id: 5, text: "Holiday" },
            
          ]:appointmentData.type===1? [{id:1, text:'Available'}]:appointmentData.type===2?[{id:2, text:'Break'}]:[{id:5, text:'Holiday'}]}
          style={{ width: '242px', textTransform:"uppercase" }}
        />
        {holidayError && <div style={{fontSize:'12px', color:'#FF0000'}}>You have active bookings for the day/time selected. Cancel them to set Break/Holiday</div>}
        {slotError && <div style={{fontSize:'12px', color:'#FF0000'}}>Schedule already exists for the date/time selected.</div>}
        <>{initType!==undefined && <div style={{fontSize:'14px', color:'#FF0000'}}>*** You cannot edit a schedule and can only delete it ^</div>}</>
        {initType===undefined && <span style={{fontSize:'10px'}}>AVAILABLE - Please note students will only be able to book a lesson at your available time
          <br/>BREAK - Please mark your break timings(ie, Lunch / Prayer)
        </span>}</>}

        
        
        <AppointmentForm.Label text="Shift Start Date" type="title" />
        { load && <AppointmentForm.DateEditor
          placeholder="Date"
          value={appointmentData.startDate}
          onValueChange={onStartDateChange}
          excludeTime={true}
          readOnly={true}
          id={"shift_start_date"}
          format={"DD-MM-YYYY"}

        />}

        {appointmentData.type!==5 && (<><AppointmentForm.Label text="Shift Start Time" type="title" />
        {/* <input
        type="time"
        placeholder="HH:MM am/pm"
        value={`${new Date(appointmentData.startDate).getHours()}:${new Date(appointmentData.startDate).getMinutes()===0?"00":new Date(appointmentData.startDate).getMinutes()}`}
        onChange={onStartTimeChange}
        min="09:00"
        max="20:30"
        // showSecond={false}
        // style={{ width: 300 }}
        // defaultValue={moment()}
        // onChange={onChange}
      /> */}
        {startDateError && <div style={{fontSize:'12px', color:'#FF0000'}}>Schedules cannot be added for past date/time</div>}
        <TimePicker

          showSecond={false}
          style={{ width: 100 }}
          defaultValue={moment(appointmentData.startDate)}
          onChange={onStartTimeChange}
          use12Hours={true}
          minuteStep={30}
          className="input-group"
          inputReadOnly={(appointmentData.type==='5' || initType!==undefined)?true:false}
          {...openClose}
          
        />
        <AppointmentForm.Label text="Shift End time " type="title" />
        {endDateError  && <div style={{fontSize:'12px', color:'#FF0000'}}>End date/time should be greater than future start date/time </div>}
        <TimePicker
          showSecond={false}
          style={{ width: 100 }}
          defaultValue={moment(appointmentData.endDate)}
          onChange={onEndTimeChange}
          use12Hours={true}
          minuteStep={30}
          className="input-group"
          inputReadOnly={appointmentData.type==='5' || initType!==undefined ?true:false}
          {...openClose}
        /></>)}
        <br />

        {appointmentData.type==='4' && 
        <div className="btn btn-danger w-100" onClick={()=>{navigate('/cancel?v='+window.localStorage.getItem('appVersion'),{state:{booking:appointmentData.bookingdata}});}}>Cancel</div>
        }

        {appointmentData.type!=='4' && appointmentData.type!==5 && initType===undefined &&
        <AppointmentForm.BooleanEditor
          label="Repeat"
          type="title"
          value={appointmentData.isRepeat}
          onValueChange={onisRepeatChange}
          
        />}

        {appointmentData.isRepeat  && (
          <>
            <AppointmentForm.Label text="Repeat on days" type="title" />
            <div>
              <div className={days["Monday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Monday", "val": !days["Monday"] })}>M</div>
              <div className={days["Tuesday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Tuesday", "val": !days["Tuesday"] })}>T</div>
              <div className={days["Wednesday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Wednesday", "val": !days["Wednesday"] })}>W</div>
              <div className={days["Thursday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Thursday", "val": !days["Thursday"] })}>T</div>
              <div className={days["Friday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Friday", "val": !days["Friday"] })}>F</div>
              <div className={days["Saturday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Saturday", "val": !days["Saturday"] })}>S</div>
              <div className={days["Sunday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Sunday", "val": !days["Sunday"] })}>S</div>
            </div>
            <input type="hidden" value={appointmentData.rdays} />

            <AppointmentForm.Label text="Schedule expires on" type="title" />
            <label>
            {dateError && (new Date(appointmentData.tillDate).getTime()<new Date(appointmentData.startDate).getTime()) && (<span className="text-red">Please enter a future date</span>)}
            {dateError && (((new Date(appointmentData.tillDate).getTime()-new Date(appointmentData.startDate).getTime())/(1000*60*60*24))>90) && (<span className="text-red">Repeat days cannot be more than 90 days</span>)}
            <input
              id="untillDate"
              type="text"
              placeholder="End Date"
              //value={appointmentData.tillDate}
              onChange={onTillDateChange}
              className="form-control"
              min={moment().add(1,"days").format('YYYY-MM-DD')}
              readOnly
              value={appointmentData.tillDate?moment(appointmentData.tillDate).format('DD-MM-YYYY'):moment(appointmentData.startDate).add(7,'days').format('DD-MM-YYYY')}
            />
            </label>
          </>
        )}
      </div>}
    </></>
  )
}

const styles = (theme) => ({

  appointment: {
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.6,
    },
    backgroundColor: "#01C1FF",
  }

});


const Appointment = ({ children, style, data, ...restProps }) => (
  <Appointments.Appointment
    {...restProps}
    style={
      data.type == 1
        ? {
          ...style,
          backgroundColor: "#01C1FF",
          borderRadius: "5px",
        }
        : data.type == 2
          ? {
            ...style,
            backgroundColor: "#FA011D",
            borderRadius: "5px",
          }
          : data.type == 4
          ? {
            ...style,
            backgroundColor: "#00BB1D",
            borderRadius: "5px",
          }
          : {
            ...style,
            backgroundColor: "#CAA110",
            borderRadius: "5px"
          }
    }

  >
    {children}
  </Appointments.Appointment>
);



const BookingSchedule = props => {
  const [mydata, setMyData] = useState(props.data);
  const [currentDate, setDate] = useState(Date.now());
  const [currentViewName, serViewName] = useState("Week")
  const [isRepeat, setRepeat] = useState(false)
  const dispatch = useDispatch();
  const [data, setAppointmentData] = useState(props.data);
  const [load, setLoad] = useState(1);
  const dateVal = {currentDate, setDate};

  const setCurrentViewName = nextViewName => {
    serViewName(nextViewName)
  }

  const setCurrentDate = nextDate => {
    setDate(nextDate)
  }

 

  async function submitSchedule(data) {
    try {
      
      let finaldata = [];
      for(let i=0;i<data.length;i++){
        if(moment(data[i].startDate)<moment.now() || data[i].endDate<moment.now()){
          notify.show("Please fix errors", "error",-1);
          return;
        }
        if(data[i].type===5){
          data[i].startDate=moment(moment(data[i].startDate).format('YYYY-MM-DD 00:00:00'));
          data[i].endDate=moment(moment(data[i].endDate).format('YYYY-MM-DD 23:59:00'));
        }

        //console.log(data[i]);
        //Add Slots only for Available
        if(data[i].type===1 || data[i].type===2 || data[i].type===5) finaldata.push(data[i]);
      }

      notify.show("Saving. Please Wait...", "success",-1);

      let resp = await postMethod("/addslots", {
        data: finaldata,
        tz: new Date().getTimezoneOffset(),
      })
      
      props.reloadSlots();
      setTimeout(
        () => {notify.hide(); notify.show("Schedule Saved Successfully", "success")},
        100
      )
      
    } catch (e) {
      //console.log(e);
      setTimeout(
        () => notify.show("Server Error! Please try again later.", "error"),
        100
      )
    }
  }

  const commitChanges = ({ added, changed, deleted }) => {


    let data = props.data;
    let submitData  = null;

    const weekday = new Array();
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    if (added) {
      if (added.isRepeat) {
        let start = new Date(added.startDate)

        //console.log(added.tillDate);
	      if(added.tillDate < added.startDate){
          added.tillDate = moment(added.startDate).add(7,'days').format('yyyy-MM-DD')
        }

        let till = moment(`${added.tillDate} 23:59:59`,'yyyy-MM-DD HH:mm:ss');
        let end = new Date(added.endDate)
        //console.log(till);
        let loop = new Date(start)
        let tillDate = new Date(end)
        let startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0
        let arr = []


        while (loop <= till) {
          let obj = {}
          obj["id"] = startingAddedId;
          obj["startDate"] = new Date(loop);
          obj["endDate"] = new Date(tillDate);
          obj["type"] = added.type;

          //console.log(weekday[new Date(loop).getUTCDay()],":::",added.rdays[weekday[new Date(loop).getUTCDay()]])

          let isHoliday = false;

          for(let h=0; h<props.holidays.length; h++){
            if(new Date(props.holidays[h].date + ' 00:00:00')<obj["startDate"] && new Date(props.holidays[h].date+ ' 23:59:59')>obj["endDate"] ){
              isHoliday = true; break;
            }
          }

          if(!isHoliday){
            if(!added.rdays) arr.push(obj);
            else if(added.rdays[weekday[new Date(loop).getDay()]]) arr.push(obj);
          }

          let newDate = loop.setDate(loop.getDate() + 1)
          let newTillDate = tillDate.setDate(tillDate.getDate() + 1)
          loop = new Date(newDate)
          loop.setHours(start.getHours(), start.getMinutes(), 0, 0)
          tillDate = new Date(newTillDate)
          startingAddedId++
        }
        data = [...arr]
        submitData = [...arr];
      } else {

        let isHoliday = false;
          for(let h=0; h<props.holidays.length; h++){
            if(new Date(props.holidays[h].date + ' 00:00:00')<added["startDate"] && new Date(props.holidays[h].date)>added["endDate"] + ' 23:59:59'){
              isHoliday = true; break;
            }
          }
        const startingAddedId =
        data.length > 0 ? data[data.length - 1].id + 1 : 0
        //added.startDate = moment(added.startDate).format('YYYY-MM-DD HH:mm:ss')
        //added.endDate = moment(added.endDate).format('YYYY-MM-DD HH:mm:ss');
        //console.log(added);
        let newData = added;
        newData.startDate = moment(added.startDate).toDate();
        newData.endDate = moment(added.endDate).toDate();
        newData.data = JSON.stringify(newData);
        data.unshift({ id: startingAddedId, ...newData })
        //console.log(data);
        submitData = [{ id: startingAddedId, ...added }];
      }

      //props.setAppointments(submitData);
      setMyData(submitData);
      props.setSlots({data: data});
      setLoad(load+1);
      //props.setAppointments(data)
      //console.log(Appointments.appointmentComponent);
      submitSchedule(submitData)
    }
    if (changed) {


      data.map((appointment) => {


        if (changed[appointment.id]) {
          props.updateSlots(appointment.id, { ...changed[appointment.id] })

        }
      })

    }
    if (deleted !== undefined) {
      //console.log(data);

    
      let deleteddata = data.filter(appointment => appointment.id === deleted)
      
      if(deleteddata[0].type==="4"){
          navigate('/cancel?v='+window.localStorage.getItem('appVersion'),{state:{booking:deleteddata[0].bookingdata}});
      }else{
        data = data.filter(appointment => appointment.id !== deleted)
        props.setAppointments(data)
        props.removeSlots(deleted, deleteddata);
      }
    }


  }

  const resources = [{
    fieldName: 'type',
    title: 'Types',
    instances: props.data,
  }];

  useEffect(()=>{
    document.getElementsByClassName('MuiToolbar-regular')[0].append(document.getElementById('add-schedule'));
    //console.log(document.getElementsByClassName('MuiButtonBase-root'));
    setTimeout(()=>{document.getElementsByClassName('MuiButtonBase-root')[2]?.setAttribute('style','position:absolute;top:-20px;display:block');},200);
  },[]);
  
  var touchstart = 0;
  var oLeft = 0;

  useEffect(()=>{
    //document.getElementsByClassName('MuiToolbar-regular')[0].append(document.getElementById('add-schedule'));
    //console.log("attaching");

    if(currentViewName === 'Day') return;

    const handleHScroll= (event) => {
      //console.log(event.target);
      //console.log(document.getElementsByClassName('MuiTable-root')[3].parentNode.parentNode.parentNode.parentNode.scrollLeft);

      if(document.getElementsByClassName('MuiTable-root')[0].parentNode.parentNode.parentNode.style.position!=='fixed' || event.target.className.indexOf('VerticalAppointment')>=0) return;
      document.getElementsByClassName('MuiTable-root')[0].parentNode.style.position='relative';
      document.getElementsByClassName('MuiTable-root')[0].parentNode.style.left=event.wheelDeltaX<=0?(event.wheelDeltaX+event.target.scrollWidth+document.getElementsByClassName('MuiTable-root')[3].parentNode.parentNode.parentNode.parentNode.scrollLeft)+'px':(event.deltaX+document.getElementsByClassName('MuiTable-root')[3].parentNode.parentNode.parentNode.parentNode.scrollLeft)+'px';
    }
    
    const handleTScroll= (event) => {
      //console.log(event);
      if(event.changedTouches[0].clientX <=event.touches[0].screenX && event.changedTouches[0].clientX>=0){
        //console.log(event.changedTouches[0].clientX)
        //console.log(event.target.scrollWidth);
        //console.log(touchstart,'==',event.changedTouches[0].clientX, '==',event.changedTouches[0].clientX-touchstart);
        if(oLeft === 0 && touchstart===0){
          touchstart = parseInt(event.changedTouches[0].clientX);
        }else{
        
          if(event.changedTouches[0].clientX < touchstart){
            oLeft =  parseInt(event.changedTouches[0].clientX) - touchstart; 
          }else{
            oLeft = parseInt(event.changedTouches[0].clientX) + touchstart; 
          }
        }

        //touchstart = parseInt(event.changedTouches[0].clientX);
        //touchstart = touchstart + touchstart + event.changedTouches[0].clientX;
        // let oLeft = touchstart;
        if(oLeft<-(event.target.scrollWidth+event.target.offsetWidth))
           oLeft = -(event.target.scrollWidth+event.target.offsetWidth-20)
        else if(oLeft>0)
           oLeft = 2;
        //console.log(touchstart,'==',oLeft);
        //console.log(oLeft);
      
      //console.log(document.getElementsByClassName('MuiTable-root')[3].firstChild.firstChild.firstChild.offsetLeft);

        if(document.getElementsByClassName('MuiTable-root')[0].parentNode.parentNode.parentNode.style.position!=='fixed' || event.target.className.indexOf('VerticalAppointment')>=0) return;
        document.getElementsByClassName('MuiTable-root')[0].parentNode.style.position='relative';
        document.getElementsByClassName('MuiTable-root')[0].parentNode.style.left=oLeft+'px';
      
      }
    }

    const handleScroll = (event) => {
      //setScrollTop(window.scrollY);
      if(window.scrollY>100){
        document.getElementsByClassName('MuiTable-root')[0].parentNode.parentNode.parentNode.setAttribute('style','position:fixed; top: 49px');
      }else{
        document.getElementsByClassName('MuiTable-root')[0].parentNode.removeAttribute('style');
        document.getElementsByClassName('MuiTable-root')[0].parentNode.parentNode.parentNode.removeAttribute('style');
      }

       

      //console.log(document.getElementsByClassName('MuiTable-root')[3].parentNode.parentNode.parentNode.parentNode.parentNode.scrollLeft);
      
    };

    const handleT = (event) =>{
      oLeft = 0;
    }

    document.getElementsByClassName('MuiToolbar-root')[0]?.nextSibling.firstChild.setAttribute('style','height:'+parseInt(window.innerHeight-120)+'px;overflow-y:auto');


    //document.getElementsByClassName('MuiTable-root')[3]?.nextSibling.firstChild.style.height = window?.innerHeight-120 + 'px';
    // window.addEventListener('scroll', handleScroll);
    // document.getElementsByClassName('MuiTable-root')[3]?.nextSibling.addEventListener('wheel',handleHScroll);
    
    // document.getElementsByClassName('MuiTable-root')[3]?.addEventListener('touchmove',handleTScroll);
    // document.getElementsByClassName('MuiTable-root')[3]?.addEventListener('touch',handleT);

    // setTimeout(() => {      
    //   let childDivs = document.getElementsByClassName('MuiTable-root')[3]?.nextSibling.children;  
    //   for(let i=0;i<childDivs.length;i++){
    //     childDivs[i].addEventListener('touchmove',handleTScroll);
    //     childDivs[i].addEventListener('touch',handleT);
    //   }
    // }, 2000);


    return () => {
      // window.removeEventListener('scroll', handleScroll);
      // document.getElementsByClassName('MuiTable-root')[3]?.nextSibling.removeEventListener('wheel',handleHScroll);
      // document.getElementsByClassName('MuiTable-root')[3]?.removeEventListener('touchmove',handleTScroll);
      // document.getElementsByClassName('MuiTable-root')[3]?.removeEventListener('touch',handleT);

      // let childDivs = document.getElementsByClassName('MuiTable-root')[3]?.nextSibling.children;
      // for(let i=0;i<childDivs.length;i++){
      //   childDivs[i].removeEventListener('touchmove',handleTScroll);
      //   childDivs[i].removeEventListener('touch',handleT);
      // }
    };
  });

  useEffect(()=>{
    setMyData(props.data);
  },[props.data]);

  //console.log(DateContext.Consumer._currentValue);
  return  (
    <DateContext.Provider value={dateVal}>
    <div className="main-sch" k={load}>
      <Paper>
        <Scheduler data={mydata} timeZone="UTC" id={"scheduleContainer"}>
          <ViewState
            currentDate={currentDate}
            currentViewName={currentViewName}
            onCurrentViewNameChange={setCurrentViewName}
            onCurrentDateChange={setCurrentDate}
          />
          <EditingState onCommitChanges={commitChanges} />
          <IntegratedEditing />
          <DayView
            // startDayHour={9}
            // endDayHour={22}
            cellDuration={60}
            showAllDayTitle={true}
          />
          <WeekView />

          <Toolbar />
          <TodayButton />
          <ViewSwitcher />
	  
	  <div id="add-schedule" onClick={()=>{
              var event = new MouseEvent('dblclick', {
                'view': window,
                'bubbles': true,
                'cancelable': true
              });

              //document.getElementsByClassName('MuiTable-root')[3].getElementsByClassName('MuiTableRow-root')[0].firstChild.getElementsByTagName('p')[0].getAttribute('class');

              let currDate = new Date();
              let slotHourPos = parseInt(currDate.getHours());
              let slotMinPos = parseInt(currDate.getMinutes());

             
              
              if(document.getElementsByClassName('MuiSelect-nativeInput')[0].value!=='Week'){
                let slotPos = slotHourPos+1;
                //document.getElementsByClassName('MuiTable-root')[3].getElementsByClassName('MuiTableRow-root')[0].firstChild.dispatchEvent(event);
                document.getElementsByClassName('MuiTable-root')[3].getElementsByClassName('MuiTableRow-root')[slotPos]?.getElementsByTagName('td')[0].dispatchEvent(event);
              }else{

                let slotPos = Math.ceil(slotHourPos*2)+(slotMinPos>30?2:1);

                let dateClass = document.getElementsByClassName('MuiTable-root')[0].getElementsByClassName('MuiTableRow-root')[0].firstChild.getElementsByTagName('p')[0].getAttribute('class');

                let datepos = currDate.getDay();

                let hasHighLightedDate = false;

                for(let i=0; i<7;i++){
                  let currDateClass = document.getElementsByClassName('MuiTable-root')[0].getElementsByClassName('MuiTableRow-root')[0].getElementsByTagName('td')[i].getElementsByTagName('p')[0].getAttribute('class');

                  if(currDateClass.indexOf('highlightedText')>=0 || currDateClass.split(' ').length>1){
                    hasHighLightedDate = true;
                    break;
                  }
                }

                if(dateClass.indexOf('highlightedText')===-1 && !hasHighLightedDate){
                  datepos = 0;
		  slotPos = 0;
		}
                  
                document.getElementsByClassName('MuiTable-root')[3].getElementsByClassName('MuiTableRow-root')[slotPos].getElementsByTagName('td')[datepos].dispatchEvent(event);
                
              }
              }
            }
            class="btn btn-primary" style={{width: '48px', height: '48px', padding: '0px', fontSize:'32px', marginTop:'10px', marginLeft: '10px',  zIndex: 1000}}>+</div>

          <EditRecurrenceMenu />
	  <DateNavigator />
          <Appointments appointmentComponent={Appointment} id="appointmentView"/>
          <AppointmentTooltip
            // showCloseButton
            // showDeleteButton
            // showOpenButton
            visible={false}
          />
          <AppointmentForm basicLayoutComponent={BasicLayout} user={props.user}/>
        </Scheduler>
      </Paper>
    </div>
    </DateContext.Provider>
  )
}

export default BookingSchedule
